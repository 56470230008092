import { NextSeo } from 'next-seo';
import HomeContent from '@/src/common/screens/HomeContent/HomeContent';
import { getHomeMeta } from '@/src/common/utils/homeMeta';
import { AuthenticationPlatform } from '../common/utils/auth/AuthenticationPlatform';

function Home() {
  return (
    <>
      <NextSeo {...getHomeMeta()} />
      <HomeContent />
    </>
  );
}

export default AuthenticationPlatform(Home);
