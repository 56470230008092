import { Divider, Typography, Link } from '@grupo-sbf/motriz-nike';
import { media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';
import Button from '@/src/common/components/Button/Button';
export const HorizontalDivider = styled(Divider).attrs({
  orientation: 'horizontal',
})`
  ${({ theme }) => css`
    border-color: ${theme.color.neutral[300]};
  `}
`;

export const ContainerButton = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing[300]} ${theme.spacing[0]} ${theme.spacing[500]};
  `}
`;

export const TitleTextModal = styled(Typography).attrs({
  type: 'subtitle',
  size: 'small',
  as: 'h4',
})`
  ${({ theme }) => css`
    padding: ${theme.spacing[100]};
    text-align: left;
  `}
`;

export const LinkModal = styled(Link)`
  ${({ theme }) => css`
    padding: ${theme.spacing[100]};
    text-align: left;
    font-size: ${theme.fontSize.xtiny};
  `}
`;

export const ParagraphTextModal = styled(Typography).attrs({
  type: 'paragraph',
  weight: 'normal',
})`
  ${({ theme }) => css`
    padding: ${theme.spacing[100]};
    text-align: left;
  `}
`;

export const ListItem = styled.li`
  ${({ theme }) => css`
    text-align: left;
    font-size: ${theme.fontSize.xtiny};
    margin-left: ${theme.spacing[500]};
    padding: ${theme.spacing[100]} ${theme.spacing[0]};
  `}
`;

export const ListWrapper = styled.ul`
  ${({ theme }) => css`
    padding: ${theme.spacing[0]};
  `}
`;

export const ButtonModal = styled(Button)`
  ${media.lessThan('large')} {
    width: 100%;
  }
`;
