import { Typography } from '@grupo-sbf/motriz-nike';
import Link from 'next/link';
import CardRequest from '@/src/common/components/CardRequest';
import { Loader } from '@/src/common/components/Loader';
import ProductReturn from '@/src/common/components/ProductReturn';
import { getCoreRoutes } from '@/src/common/config/routes';
import { useAuthSession } from '@/src/common/hooks/useAuthSession';
import { EmptyMessage } from '@/src/common/screens/RequestsContent/RequestsContent.styled';
import { useMyRequestsList } from '@/src/common/services/myRequests';
import {
  ContainerContent,
  ContainerHeader,
  GridContent,
  HeaderRequest,
  ParagraphHeader,
  ParagraphGreeting,
  TitleHeader,
  WrapperRequest,
  CardsArea,
  WrapperProduct,
  ButtonHome,
} from './HomeContent.styled';

const coreRoutes = getCoreRoutes();

const HomeContent = () => {
  const { clientName } = useAuthSession();
  const { data, isFetching } = useMyRequestsList(4);
  const exchangeList = data?.exchanges || [];

  return (
    <main>
      <ContainerHeader>
        <TitleHeader>Autoatendimento</TitleHeader>
      </ContainerHeader>
      <ContainerContent>
        <ParagraphHeader>Olá, {clientName} !</ParagraphHeader>
        <ParagraphGreeting>
          Este é o portal de autoatendimento da Nike, onde você tem autonomia
          para tratar suas solicitações.
        </ParagraphGreeting>

        <GridContent>
          <WrapperProduct>
            <ProductReturn />
          </WrapperProduct>
          <WrapperRequest>
            <HeaderRequest>
              <Typography type="subtitle">Minhas solicitações</Typography>
              <Typography type="paragraph">
                Acompanhe as suas últimas solicitações:
              </Typography>
            </HeaderRequest>
            {isFetching ? (
              <Loader isFullScreen={false} />
            ) : (
              <>
                {exchangeList.length === 0 ? (
                  <EmptyMessage>Nenhuma solicitação foi realizada</EmptyMessage>
                ) : (
                  <>
                    <CardsArea>
                      {exchangeList.map((exchange) => (
                        <CardRequest
                          protocol={exchange.protocol}
                          tagCode={exchange.tagCode}
                          modelDescription={exchange.modelDescription}
                          exchangedDate={exchange.exchangedDate}
                          exchange={exchange}
                          key={exchange.protocol}
                        />
                      ))}
                    </CardsArea>
                    <Link href={coreRoutes.myRequests} passHref>
                      <ButtonHome>Acompanhar solicitações</ButtonHome>
                    </Link>
                  </>
                )}
              </>
            )}
          </WrapperRequest>
        </GridContent>
      </ContainerContent>
    </main>
  );
};

export default HomeContent;
