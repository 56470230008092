import { Typography } from '@grupo-sbf/motriz-nike';
import Link from 'next/link';
import { useState } from 'react';
import Button from '@/src/common/components/Button/Button';
import ReturnModal from '@/src/common/components/ReturnModal/ReturnModal';
import { getCoreRoutes } from '@/src/common/config/routes';
import { trackingEvent } from '@/src/common/utils/tracking';
import {
  ContainerType,
  ReturnBox,
  ContainerTypeBtn,
  ReturnLink,
} from './ProductReturn.styled';

const ProductReturn = () => {
  const coreRoutes = getCoreRoutes();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openModal = () => {
    trackingEvent({
      event: 'select_content',
      content_type: 'link',
      item_id: 'return_help',
    });
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <ReturnBox>
      <ContainerType>
        <Typography type="subtitle">Devolução de produto</Typography>

        <Typography type="paragraph">
          Se precisar devolver o seu produto, você pode optar por vale troca ou
          solicitar a restituição do valor pago.
        </Typography>

        <ReturnLink onClick={openModal} hasUnderline>
          {' '}
          Entenda como funciona a devolução
        </ReturnLink>
      </ContainerType>
      <ReturnModal isOpen={isOpen} onClose={closeModal} />
      <ContainerTypeBtn>
        <Link href={coreRoutes.returnRoute} passHref>
          <Button variant="primary" as="a">
            Solicitar devolução de produto
          </Button>
        </Link>
      </ContainerTypeBtn>
    </ReturnBox>
  );
};

export default ProductReturn;
