import { Link } from '@grupo-sbf/motriz-nike';
import { media } from '@grupo-sbf/motriz-utils';
import styled, { css } from 'styled-components';

export const ContainerType = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[300]};
    align-items: center;

    ${media.greaterThan('medium')} {
      align-items: baseline;
    }

    a {
      width: auto;
    }
  `}
`;

export const ContainerTypeBtn = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[300]};
  `}
`;

export const ReturnBox = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: ${theme.spacing[300]};
    padding: ${theme.spacing[300]} ${theme.spacing[200]};
    text-align: center;

    ${media.greaterThan('medium')} {
      text-align: initial;
      gap: ${theme.spacing[600]};
      padding: ${theme.spacing[500]} ${theme.spacing[600]};
      grid-template-columns: 1fr 278px;
    }
  `}
`;

export const ReturnLink = styled(Link)`
  cursor: pointer;
`;
