import { NextSeoProps } from 'next-seo';

/**
 *  builds the meta tags of home page
 */
export const getHomeMeta = (): NextSeoProps => {
  const title = 'Autoatendimento - Nike.com';
  const description =
    'Plataforma de Autoatendimento Nike.com - A Maior Loja Nike';

  const url = 'https://autoatendimento.nike.com.br';

  return {
    title,
    description,
    canonical: url,
    openGraph: {
      title,
      description,
      url,
      images: [
        {
          url: '/images/meta/open-graph-main-image.jpg',
          width: 1920,
          height: 1088,
        },
      ],
    },
  };
};
