import { signIn, signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import { destroyCookie, setCookie } from 'nookies';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { environment } from '..';
import { Loader } from '../../components/Loader';
import { useWebsiteUi } from '../../contexts/WebsiteUIContext';
import { useAuthSession } from '../../hooks/useAuthSession';

type WithAuthComponent<P> = React.FunctionComponent<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type SignInProps = {
  error: string | undefined;
  status: number;
  ok: boolean;
  url: string | null;
};

export const AuthenticationPlatform = <P extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<P>,
): WithAuthComponent<P> => {
  const AuthComponent = (props: P) => {
    const router = useRouter();
    const { isUnauthenticated, tokenAuthNike, isLoading } = useAuthSession();
    const { websiteUiValues, setWebsiteUiPartialValues } = useWebsiteUi();
    const queryClient = useQueryClient();
    const { initializedApplication } = websiteUiValues;
    const accessTokenCookieName = 'access-token';

    const clearSession = async () => {
      await signOut({ redirect: false });
      destroyCookie(undefined, accessTokenCookieName);
      if (environment.isProdEnv) {
        return router.push('https://www.nike.com.br/');
      } else if (environment.isDevEnv) {
        const accessToken =
          prompt('[DEV-MODE] Insira o accessToken | idToken:') || '';
        setCookie(undefined, accessTokenCookieName, accessToken);
      }
      return router.reload();
    };

    const loginHandling = () => {
      queryClient.invalidateQueries('myRequestsList');
      setWebsiteUiPartialValues({
        initializedApplication: true,
      });

      return true;
    };

    useEffect(() => {
      if (!initializedApplication) {
        signIn('credentials', {
          token: tokenAuthNike,
          redirect: false,
        }).then((response) => {
          return (response as unknown as SignInProps)?.error
            ? clearSession()
            : loginHandling();
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initializedApplication, tokenAuthNike]);

    if (isLoading || isUnauthenticated || !initializedApplication) {
      return <Loader />;
    }
    return !isUnauthenticated ? <WrappedComponent {...props} /> : <></>;
  };

  return AuthComponent;
};
