import { Modal } from '@grupo-sbf/motriz-nike';
import { ReturnModalProps } from '@/src/common/components/ReturnModal/ReturnModal.types';
import {
  HorizontalDivider,
  TitleTextModal,
  ParagraphTextModal,
  ContainerButton,
  LinkModal,
  ListWrapper,
  ListItem,
  ButtonModal,
} from './ReturnModal.styled';

const ReturnModal = ({ isOpen, onClose }: ReturnModalProps) => {
  return (
    <Modal.Blank
      isOpen={isOpen}
      onClose={onClose}
      title="Entenda como funciona a devolução"
    >
      {' '}
      <HorizontalDivider />
      <ParagraphTextModal>
        {' '}
        Se você comprou e recebeu um produto que não te serviu, ou não era da
        cor ou modelo que você queria, você tem até 30 dias corridos a partir do
        recebimento para solicitar a devolução. Caso o motivo da devolução seja
        por defeito no produto, o prazo para devolução será de 90 dias a partir
        do recebimento.
      </ParagraphTextModal>
      <TitleTextModal>Como devolver o produto?</TitleTextModal>
      <ParagraphTextModal>
        A devolução para troca pelo site é feita por meio de postagem nos
        Correios. Ao solicitar a troca, te daremos um código de postagem e você
        deverá comparecer em qualquer agência credenciada dos Correios com o
        produto pronto para envio.
      </ParagraphTextModal>
      <ParagraphTextModal>
        Se você quer saber onde é agência mais próxima, acesse o site:
      </ParagraphTextModal>
      <LinkModal
        href="https://mais.correios.com.br/app/index.php"
        target="_blank"
        hasUnderline
      >
        https://mais.correios.com.br/app/index.php
      </LinkModal>
      <TitleTextModal>Como preparar o produto para envio?</TitleTextModal>
      <ListWrapper>
        <ListItem>
          Para devolver o produto ele precisa estar embalado, sendo assim,
          utilize a embalagem original do lado inverso – a parte preta. Se você
          não tiver mais a embalagem, use papel pardo, caixa ou algo similar. O
          manual de instruções e os acessórios precisam ser devolvidos junto com
          o produto também.
        </ListItem>
        <ListItem>
          O endereço de destino não precisa ser informado na embalagem, a
          agência saberá qual o endereço de devolução pelo próprio código de
          postagem.
        </ListItem>
        <ListItem>
          <strong>Importante:</strong> Você deve colocar uma cópia da sua nota
          fiscal dentro da embalagem do produto que será devolvido e levar uma
          outra cópia em mãos, para que ela possa ser anexada no lado de fora da
          embalagem. Caso devolva mais de um item, coloque uma nota fiscal
          dentro de cada embalagem e tenha em mãos as cópias para serem fixadas
          nos pacotes quando você for até o Correios.
        </ListItem>
      </ListWrapper>
      <ContainerButton>
        <ButtonModal onClick={onClose}>Entendi</ButtonModal>
      </ContainerButton>
    </Modal.Blank>
  );
};

export default ReturnModal;
